import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '@environments/environment';
import { TravelAgencySearchResponse } from '@shared/models/agency-search-response';
import { ProfileTypeEnum } from '@shared/models/types.enum';
import { Travelagency, TravelagencySystemInterface } from '@shared/models/agency';
import { TestLoginResponse } from '@shared/models/test-login-response';
import { GenericSetupResponse } from '@shared/models/generic-setup-response';
import { AgencyReferencedata } from '@models/agency-referencedata';
import { AgencyInterfaceToggleData } from '@models/agency-interface-toggle-data';
import { TravelGroupDefinition } from '@models/travel-group-definition';

@Injectable({
  providedIn: 'root'
})
export class TravelAgencyService {

  constructor(private readonly http: HttpClient) {
  }

  search(params: {
    q: string;
    page?: number;
    pageSize?: number
  }): Observable<TravelAgencySearchResponse> {
    let httpParams = new HttpParams()
      .set('q', params.q)
      .set('pageSize', params.pageSize ?? 25)
    if (params.page !== undefined) {
      httpParams = httpParams.set('page', params.page);
    }
    return this.http.get<TravelAgencySearchResponse>(`${environment.apiBaseUrl}/api/v1/agencies`, {params: httpParams});
  }

  read(uuid: string): Observable<Travelagency> {
    return this.http.get<Travelagency>(`${environment.apiBaseUrl}/api/v1/agency/${uuid}`);
  }

  getNewAgencyTemplate(): Observable<Travelagency> {
    return this.http.get<Travelagency>(`${environment.apiBaseUrl}/api/v1/agency/new`);
  }

  getSetup(uuid: string, forEditing = false, profileType?: ProfileTypeEnum | undefined): Observable<GenericSetupResponse> {
    let httpParams = new HttpParams();
    if (forEditing) {
      httpParams = httpParams.set('forEditing', true);
    }
    if (profileType) {
      httpParams = httpParams.set('profileType', profileType);
    }
    return this.http.get<GenericSetupResponse>(`${environment.apiBaseUrl}/api/v1/configuration/setup/agency/${uuid}`, { params: httpParams });
  }

  saveSetup(agencyUuid: string, setup: GenericSetupResponse): Observable<GenericSetupResponse> {
    return this.http.patch<GenericSetupResponse>(`${environment.apiBaseUrl}/api/v1/configuration/setup/agency/${agencyUuid}`, setup);
  }

  uploadSetup(agencyUuid: string, file: File): Observable<GenericSetupResponse> {
    const formData = new FormData();
    formData.append('setupFile', file, file.name);
    return this.http.patch<GenericSetupResponse>(`${environment.apiBaseUrl}/api/v1/configuration/setup/agency/${agencyUuid}/upload`, formData);
  }

  getUmbrellaNetSaml(): Observable<any> {
    return this.http.get<any>(`${environment.apiBaseUrl}/api/v1/agency/saml/UMBNET2.json`);
  }

  save(uuid: string | undefined, request: Travelagency): Observable<Travelagency> {
    if (uuid) {
      return this.http.patch<Travelagency>(`${environment.apiBaseUrl}/api/v1/agency/${uuid}`, request);
    }
    return this.http.post<Travelagency>(`${environment.apiBaseUrl}/api/v1/agency`, request);
  }

  delete(uuid: string): Observable<void> {
    return this.http.delete<void>(`${environment.apiBaseUrl}/api/v1/agency/${uuid}`);
  }

  disconnectSystemInterface(agencyUuid: string, interfaceUuid: string): Observable<void> {
    return this.http.delete<void>(`${environment.apiBaseUrl}/api/v1/agency/${agencyUuid}/publishstates/${interfaceUuid}`);
  }

  testSmtp(agencyUuid: string): Observable<void> {
    return this.http.post<void>(`${environment.apiBaseUrl}/api/v1/agency/${agencyUuid}/testsmtp`, {});
  }

  testLogin(request: TravelagencySystemInterface): Observable<TestLoginResponse> {
    return this.http.post<TestLoginResponse>(`${environment.apiBaseUrl}/api/v1/agency/testlogin`, request);
  }

  getInterfacePassword(agencyUuid: string, interfaceUuid: string): Observable<string> {
    return this.http.get<string>(`${environment.apiBaseUrl}/api/v1/secrets/agency/${agencyUuid}/interface/${interfaceUuid}/password`);
  }

  getInterfaceAttribute(agencyUuid: string, interfaceUuid: string, attributeIndex: number): Observable<string> {
    return this.http.get<string>(`${environment.apiBaseUrl}/api/v1/secrets/agency/${agencyUuid}/interface/${interfaceUuid}/attribute/${attributeIndex}`);
  }

  getPciPassword(agencyUuid: string, type: string): Observable<string> {
    return this.http.get<string>(`${environment.apiBaseUrl}/api/v1/secrets/agency/${agencyUuid}/pci/${type}`);
  }

  getPrivacyPolicyLocales(uuid: string): Observable<{ [key: string]: string }> {
    return this.http.get<{ [key: string]: string }>(`${environment.apiBaseUrl}/api/v1/agency/${uuid}/privacypolicy/locales`);
  }

  getPrivacyPolicyText(uuid: string, locale: string): Observable<string> {
    return this.http.get(`${environment.apiBaseUrl}/api/v1/agency/${uuid}/privacypolicy/${locale}.html`, { responseType: 'text' });
  }

  createPrivacyPolicy(uuid: string, locale: string, policyText: string): Observable<void> {
    return this.http.post<void>(`${environment.apiBaseUrl}/api/v1/agency/${uuid}/privacypolicy/${locale}`, {
      text: policyText
    });
  }

  updatePrivacyPolicy(uuid: string, locale: string, policyText: string): Observable<void> {
    return this.http.patch<void>(`${environment.apiBaseUrl}/api/v1/agency/${uuid}/privacypolicy/${locale}`, {
      text: policyText
    });
  }

  deletePrivacyPolicy(uuid: string, locale: string): Observable<void> {
    return this.http.delete<void>(`${environment.apiBaseUrl}/api/v1/agency/${uuid}/privacypolicy/${locale}`);
  }

  resetAllPrivacyPolicyAccepts(uuid: string): Observable<void> {
    return this.http.post<void>(`${environment.apiBaseUrl}/api/v1/agency/${uuid}/privacypolicy/reset`, {});
  }

  getReferencedata(uuid: string): Observable<AgencyReferencedata> {
    return this.http.get<AgencyReferencedata>(`${environment.apiBaseUrl}/api/v1/agency/${uuid}/referencedata`);
  }

  getInterfacesToggleData(uuid: string): Observable<AgencyInterfaceToggleData[]> {
    return this.http.get<AgencyInterfaceToggleData[]>(`${environment.apiBaseUrl}/api/v1/agency/${uuid}/interface-toggles`);
  }

  getCytricTravelGroups(agencyUuid: string, interfaceUuid: string, cytricOrganisation: string, overrideEndpoint?: string): Observable<TravelGroupDefinition[]> {
    let httpParams = new HttpParams();
    if (overrideEndpoint) {
      httpParams = httpParams.set('ep', overrideEndpoint);
    }

    return this.http.get<TravelGroupDefinition[]>(`${environment.apiBaseUrl}/api/v1/agency/${agencyUuid}/travel-groups/${interfaceUuid}/${cytricOrganisation}`, {
      params: httpParams
    });
  }

  getSendAsPassword(uuid: string): Observable<string> {
    return this.http.get<string>(`${environment.apiBaseUrl}/api/v1/secrets/agency/${uuid}/smtp-password`);
  }

}
